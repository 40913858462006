import Rails from '@rails/ujs'

import './global' //
import './admin'
import './learn'
import './player-new' //
import './preview-player' //
import './registration'
import './selectpicker'
import './shop'
import './special-offer'
Rails.start()
