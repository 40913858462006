window.addEventListener('DOMContentLoaded', function() {
  $('.special-offer').click(function () {

  })
  $('.special-offer .close').click(function (e) {
    $('.special-offer').hide()
    e.preventDefault()
    return false
  })
})
