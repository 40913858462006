window.addEventListener('DOMContentLoaded', function() {
  const $menuItems = $('#how-to > h3 > a')
  const $search = $('#how-to > div > input.search')

  $search.keyup((e) => {
    let searchQuery = $search.val()

    if (searchQuery) searchQuery = searchQuery.toLowerCase()

    $menuItems.each((idx, el) => {
      const text = ($(el).text() || '').toLowerCase()
      const match = text.indexOf(searchQuery) > -1

      if (searchQuery) {
        $(el)[match ? 'show' : 'hide']()
      } else {
        $(el).show()
      }
    })
  })
})
