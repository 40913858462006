// eslint-disable-next-line
function parameterize(str) {
  return str
    .replace(/[^a-z0-9]+/gi, '-')
    .replace(/^-*|-*$/g, '')
    .toLowerCase()
}

function toggleLoginSheet () {
  if (!$('body').data('session')) {
    $('.login-sheet').toggleClass('show')
    $('header .login').toggleClass('show')
    if (window.scrollY !== $('.login-sheet').offset().top) {
      var screenBottom = window.innerHeight
      var loginSheetBottom = $('.login-sheet').height() + $('.login-sheet').offset().top
      if (loginSheetBottom > screenBottom) {
        scrollPage($('.login').offset().top)
      }
    }
    return true
  }
  return false
}

function showLoginSheet () {
  if (!$('body').data('session')) {
    var show = function () {
      $('.login-sheet').addClass('show')
      $('header .login').addClass('show')
    }
    if (window.scrollY > 0) {
      scrollPage(0, show)
    } else {
      show()
    }
    return true
  }
  return false
}

function scrollPage (newPosition, callbacks) {
  $('html, body').animate({ scrollTop: newPosition }, 'slow', callbacks)
}

// eslint-disable-next-line
function notificationsEmpty() {
  return $('.notifications').hasClass('empty')
}

function moveMobileMenu () {
  const cont = $('.sub')
  const el = $('.sub > .inner.container .current')
  const width = cont.width() / 2
  const position = el.position()

  if (position) {
    const elementLeft = position.left
    const pos = cont.scrollLeft() + elementLeft - width
    cont.animate({ scrollLeft: pos })
  }
}

document.addEventListener('DOMContentLoaded', function () {
  $('.demand_login').click(function (event) {
    $('#redirect').val(event.target.href)
    if (showLoginSheet()) {
      return false
    }
  })

  $('header .login').click(function () {
    toggleLoginSheet()
    return false
  })

  $(document).click(function (e) {
    if (
      !$(e.target)
        .parents()
        .andSelf()
        .is('.login-sheet')
    ) {
      $('.login-sheet').removeClass('show')
      $('header .login').removeClass('show')
    }
    if (
      !$(e.target)
        .parents()
        .andSelf()
        .is('.post-track-sheet, .invite-sheet')
    ) {
      $('.post-track-sheet, .invite-sheet').removeClass('show')
    }
  })

  /* eslint-disable */
  var searchforms = $('.community.tracks form.mixdown_search, .community.groups aside section'),
    usersSearchForm = $('.community.users aside form'),
    asides = $('.community.groups.index aside, .community.tracks.index aside'),
    feedAside = $('.community.feed aside')
  /* eslint-enable */

  $('header .menu').click(function () {
    $(this).toggleClass('active')
    $('header .container').toggleClass('show')
    return false
  })

  $('a')
    .filter(function () {
      return this.hostname && this.hostname !== window.location.hostname
    })
    .attr('target', '_blank')

  $('.picture-upload-button').click((e) => {
    e.preventDefault()
    const fileinput = $(e.target).parent().find('input[type=file]')
    const img = $(e.target).parents('form').find('.picture-upload img')

    fileinput.change((e) => {
      const file = e.originalEvent.srcElement.files[0];
      const reader = new FileReader();

      reader.onloadend = function() {
        img.attr('src', reader.result);
      }

      reader.readAsDataURL(file);
    });

    fileinput.click()
  })

  $('input[name="mixdown[published]"]').on('change', (e) => {
    let campaignSettings = $('.campaign-settings', e.target.closest('form'))
    if (e.target.value === 'true') {
      campaignSettings.removeClass('campaign-settings--disabled')
      const infoSpan = campaignSettings.find('.campaign-settings__info')
      if (infoSpan) infoSpan.removeClass('campaign-settings__info--disabled')
      $('input', campaignSettings).prop('disabled', false)
    } else {
      campaignSettings.addClass('campaign-settings--disabled')
      campaignSettings.find('#mixdown_added_to_campaign_group').prop('checked', false)
      const infoSpan = campaignSettings.find('.campaign-settings__info')
      if (infoSpan) infoSpan.addClass('campaign-settings__info--disabled')
      $('input', campaignSettings).prop('disabled', true)
    }
  })
  $('input[name="mixdown[published]"]:checked').trigger('change')

  moveMobileMenu()
})
