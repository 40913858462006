// Helpers

function pad (num, size) {
  var s = '000000000' + num
  return s.substr(s.length - size)
}

function floatToTime (inp) {
  var mins = Math.floor(inp / 60.0)
  var secs = Math.floor(inp - mins * 60)
  return pad(mins, 2) + ':' + pad(secs, 2)
}

HTMLElement.prototype.hasClass = function (cls) {
  return (' ' + this.className + ' ').indexOf(' ' + cls + ' ') > -1
}

var previewPlayers = []

function PreviewPlayer (element) {
  var self = this

  var url = element.data('url')
  var title = ''
  if (element.data('title')) {
    title = element.data('title')
  }

  element.append($('<button class="play"></button>'))
  element.append($('<div class="time"><div class="title">' + title + '</div><span>00:00</span><div class="progress"></div><div class="position"></div></div>'))
  element.append($('<audio preload="none" class="audio"><source src="' + url + '" type="audio/mpeg"></audio>'))

  this.audio_tag = $('audio', element)[0]
  this.play_button = $('button', element)[0]
  this.time = $('.time', element)[0]
  this.progress = $('.progress', element)[0]
  this.position = $('.position', element)[0]
  this.current_time = $('.time span', element)[0]
  this.play_next = false

  if (element.data('play-next') == true) {
    this.play_next = true
  }

  this.play = function () {
    self.audio_tag.play()
    for (var i = 0; i < previewPlayers.length; i++) {
      var p = previewPlayers[i]
      if (p != self) {
        p.pause()
      }
    };
  }

  this.pause = function () {
    self.audio_tag.pause()
  }

  this.togglePlay = function () {
    if (self.play_button.hasClass('play')) {
      self.play()
    } else {
      self.pause()
    }
  }

  self.audio_tag.addEventListener('progress', function () {
    if (self.audio_tag.buffered.length == 0) return
    var p = (self.audio_tag.buffered.end(0) / self.audio_tag.duration)
    self.progress.style.width = p * 100.0 + '%'
  })

  self.audio_tag.addEventListener('progress', function () {
    if (self.audio_tag.buffered.length == 0) return
    var p = (self.audio_tag.buffered.end(0) / self.audio_tag.duration)
    self.progress.style.width = p * 100.0 + '%'
  })

  self.audio_tag.addEventListener('canplaythrough', function () {
    if (self.audio_tag.buffered.length == 0) return
    var p = (self.audio_tag.buffered.end(0) / self.audio_tag.duration)
    self.progress.style.width = p * 100.0 + '%'
  })

  self.audio_tag.addEventListener('timeupdate', function () {
    var p = self.audio_tag.currentTime / self.audio_tag.duration
    self.position.style.width = p * 100.0 + '%'
    self.current_time.innerHTML = floatToTime(self.audio_tag.currentTime)
  })

  self.audio_tag.addEventListener('play', function () {
    self.play_button.className = 'play-pause pause'
  })

  self.audio_tag.addEventListener('pause', function () {
    self.play_button.className = 'play-pause play'
  })

  self.audio_tag.addEventListener('ended', function () {
    if (self.play_next) {
      if (previewPlayers.indexOf(self) + 1 < previewPlayers.length) {
        var p = previewPlayers[previewPlayers.indexOf(self) + 1]
        if (p) p.play()
      }
    }
  })

  self.time.onmousedown = function (e) {
    if (self.audio_tag.readyState < 1) return
    var xpos = e.offsetX == undefined ? e.layerX : e.offsetX
    var p = xpos / self.time.offsetWidth
    self.audio_tag.currentTime = p * self.audio_tag.duration
    self.time.onmousemove = function (e) {
      var xpos = e.offsetX == undefined ? e.layerX : e.offsetX
      var p = xpos / self.time.offsetWidth
      self.audio_tag.currentTime = p * self.audio_tag.duration
    }
    self.time.onmouseup = function (e) {
      self.time.onmousemove = null
    }
  }

  self.play_button.onclick = function (e) {
    e.preventDefault()
    self.togglePlay()
  }
}

window.addEventListener('DOMContentLoaded', function() {
  $('.preview-player').each(function () {
    var el = $(this)
    previewPlayers.push(new PreviewPlayer(el))
  })
})