// Place your application-specific JavaScript functions and classes here
// This file is automatically included by javascript_include_tag :defaults

Array.prototype.remove = function (from, to) {
  var rest = this.slice((to || from) + 1 || this.length)
  this.length = from < 0 ? this.length + from : from
  return this.push.apply(this, rest)
}

window.addEventListener('DOMContentLoaded', function() {
  // alert("asd");

  $('#sidebar form.search text-field').keypress(function (e) {
    if (e.which == 13) {
      e.preventDefault()
      $('#sidebar form.search').submit()
      return false
    }
  })

  $('#sidebar form.search select').change(function (e) {
    $('#sidebar form.search').submit()
  })

  $('#sidebar ul.actions a.save').click(function () {
    $('#content form').submit()
    return false
  })

  $('form #add_genre').change(function (e) {
    var genre_id = $(this).val()
    if (!genre_id) return
    var genre_name = $(':selected', this).text()
    genre_name = genre_name.split('-').join('')
    product_genres.push(genre_id)
    $('#genre_ids').val(product_genres.join(','))
    $('#genres').append('<li genre_id="' + genre_id + '"><img src="/images/admin/cross-small.png" alt="Cross" /> ' + genre_name + '</li>')
    $('#genres li:last-child img').click(remove_genre)
    $(this).val(null)
  })

  $('#genres img').click(remove_genre)

  function remove_genre () {
    parent_li = $(this).parent()
    product_genres.remove(parent_li.index())
    $('#genre_ids').val(product_genres.join(','))
    parent_li.remove()
  }
})
